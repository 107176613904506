import * as React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { Draft, IconButton, Inline, Text, useToast } from '@resi-media/resi-ui';
import copy from 'copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { TrackEventName, TrackEventProp } from '@studio/constants/tracking-constants';
import { usePrefix } from '@studio/hooks';
import { tracking } from '@studio/store';
import { IconWrapper } from '@studio/styles/global';

type _Props = {
  dataTestId?: string;
  dataTrackId?: string;
  isIconButton?: boolean;
  name?: string;
  onClick?: () => void;
  text: string;
};

const CopyToClipboard = ({ dataTestId, dataTrackId, isIconButton = true, name, onClick, text }: _Props) => {
  const { commonT, prefixNS } = usePrefix('components:', 'copyToClipboard');
  const { trigger } = useToast();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleCopyToClipboard = () => {
    copy(text);
    const toastMsg = name ? prefixNS('withName', { name }) : prefixNS('generic');
    trigger({ content: toastMsg });
    dispatch({
      type: tracking.ActionTypes.TRACK_SEGMENT_REQUEST,
      payload: {
        eventName: TrackEventName.CLIPBOARD_COPY,
        eventProps: {
          [TrackEventProp.COPY_TYPE]: name,
        },
      },
    });

    onClick?.();
  };

  return isIconButton ? (
    <IconWrapper>
      <IconButton dataTestId="icon-button" label={commonT('copy')} onClick={handleCopyToClipboard} sizeVariant="s">
        <CopyOutlined
          data-testid={dataTestId}
          data-trackid={dataTrackId}
          style={{ color: theme.palette.primary.main }}
          title={commonT('copy')}
        />
      </IconButton>
    </IconWrapper>
  ) : (
    <Draft.Button
      dataTestId="button"
      label={commonT('copy')}
      onClick={handleCopyToClipboard}
      sizeVariant="m"
      style={{ width: theme.typography.pxToRem(125) }}
      variant="outlined"
    >
      <Inline alignItems="center">
        <CopyOutlined style={{ color: theme.palette.primary.main }} />
        <Text colorVariant="brand">{commonT('copy')}</Text>
      </Inline>
    </Draft.Button>
  );
};

CopyToClipboard.displayName = 'CopyToClipboard';

export default CopyToClipboard;
