import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import type { Customer } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/customers/@id/contacts`,
    response: {} as Customer.Get.Contact,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
};

export const id = {};
