import * as React from 'react';
import {
  CaretLeftFilled,
  CheckCircleFilled,
  CloseCircleOutlined,
  CopyOutlined,
  DeploymentUnitOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import { css, useTheme } from '@emotion/react';
import { autoPlacement, offset, shift } from '@floating-ui/react';
import {
  Inline,
  SecurityShield,
  Text,
  ToggleInput,
  Stack,
  useThemeConfig,
  Draft,
  Link,
  Hr,
  useToast,
  useForkRef,
  Broadcast,
} from '@resi-media/resi-ui';
import copy from 'copy-to-clipboard';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import { HEADER_HEIGHT, HEADER_HEIGHT_SUDO_USER } from '@studio/constants/dashboard-style-constants';
import UrlPaths from '@studio/constants/url-paths';
import { useAdmin } from '@studio/contexts/admin';
import { usePrefix } from '@studio/hooks/use-prefix';
import { useTimeMode } from '@studio/hooks/use-time-mode';
import { selectCapabilities, selectUserToggles } from '@studio/store/authentication';
import { selectCustomerData } from '@studio/store/customer';
import { AdminCmdK } from '../AdminCmdK';

type _Props = object;

type _SubViews = 'capabilities' | 'flags' | 'toggles';

/* eslint-disable import/export */
const AdminActionMenuInternal = (_props: _Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { commonT, prefixNS } = usePrefix('navigation:');
  const { trigger } = useToast();
  const {
    currentCustomer,
    handleSwitchDefault,
    isResiAdmin,
    setDemoView,
    setView,
    showAdminView,
    showDemoView,
    showSudo,
  } = useAdmin();
  const { setMode } = useThemeConfig();
  const menuRef = React.useRef<HTMLDivElement>(null);
  const customer = useSelector(selectCustomerData);
  const capabilities = useSelector(selectCapabilities) ?? [];
  const toggles = useSelector(selectUserToggles) ?? [];
  const { setTimeMode } = useTimeMode();
  const navigate = useNavigate();
  const theme = useTheme();
  const { setModal } = Draft.ModalContext.useModal();
  const ldClient = useLDClient();
  const ldFlags = ldClient?.allFlags() ?? {};
  const forkedRef = useForkRef(ref, menuRef);

  const [timeMode, setLocalTimeMode] = React.useState(localStorage.getItem('studio-time-mode') === '24hour');
  const [anchorEls, setAnchorEls] = React.useState<{
    capabilities: Element | null;
    current: _SubViews | '';
    flags: Element | null;
    toggles: Element | null;
  }>({
    current: '',
    flags: null,
    capabilities: null,
    toggles: null,
  });

  const togglePopoverView = (event: React.MouseEvent<HTMLAnchorElement>, field: _SubViews): void => {
    setAnchorEls({
      flags: null,
      capabilities: null,
      toggles: null,
      [field]: anchorEls[field] ? null : event.currentTarget,
      current: anchorEls[field] ? '' : field,
    });
  };

  const handleKeyPress = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.altKey && !event.ctrlKey && !event.shiftKey && !event.metaKey) {
        if (event.code === 'KeyK') {
          event.preventDefault();
          setModal(
            () => (
              <AdminCmdK
                is24HrView={timeMode}
                onTimeToggle={() => {
                  setLocalTimeMode(!timeMode);
                  setTimeMode(!timeMode);
                }}
              />
            ),
            { dataTestId: 'cmd-k-modal', minHVariant: 's' }
          );
        } else if (event.code === 'KeyX') {
          setDemoView(!showDemoView);
        } else if (event.code === 'KeyV') {
          setMode(theme.palette.activeMode === 'dark' ? 'light' : 'dark');
        } else if (event.code === 'KeyZ') {
          setLocalTimeMode(!timeMode);
          setTimeMode(!timeMode);
        } else if (event.code === 'KeyC') {
          setView(!showAdminView);
        } else if (event.code === 'KeyA') {
          navigate(UrlPaths.ADMIN.ORGANIZATIONS);
        }
      }
    },
    [
      navigate,
      setDemoView,
      setModal,
      setMode,
      setTimeMode,
      setView,
      showAdminView,
      showDemoView,
      theme.palette.activeMode,
      timeMode,
    ]
  );

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const CONTENT_SIZE = 220;
  const TAB_SIZE = 10;

  return (
    isResiAdmin &&
    createPortal(
      <div
        ref={forkedRef}
        css={css`
          position: fixed;
          top: calc(
            50% - ${(menuRef.current?.clientHeight ?? 0) / 2}px +
              ${(showSudo ? HEADER_HEIGHT_SUDO_USER : HEADER_HEIGHT) / 2}px
          );
          right: -${TAB_SIZE}px;
          transform: translateX(${TAB_SIZE + CONTENT_SIZE}px);
          background-color: ${theme.palette.brand.resiRed};
          color: white;
          text-align: center;
          border-top-left-radius: ${theme.shape.borderRadius.l};
          border-bottom-left-radius: ${theme.shape.borderRadius.l};
          display: flex;
          align-items: center;
          justify-content: center;
          gap: ${theme.spacing.s};
          opacity: 0.7;
          transition: transform 0.2s ease-out;
          z-index: ${theme.zIndex.tooltip + 1};

          :hover {
            ${!showDemoView &&
            `
              transform: translateX(0px);
              right: 0px;
              opacity: 0.9;
            `}
          }

          ${Boolean(Object.values(anchorEls).filter(Boolean).length) &&
          !showDemoView &&
          `
            transform: translateX(0px);
            right: 0px;
            opacity: 0.9;
          `}

          ${showDemoView &&
          `
            right: -100%;
          `}
        `}
        data-testid="admin-action-menu"
        id="admin-action-menu"
      >
        <Inline alignItems="center" gap="m" px="s" py="s">
          <SecurityShield
            color="white"
            style={{
              fontSize: theme.typography.pxToRem(20),
            }}
          />
          <Stack
            css={css`
              width: ${CONTENT_SIZE}px;
            `}
            gap="xs"
          >
            <Stack gap="0">
              <ToggleInput
                checked={theme.palette.activeMode === 'dark'}
                data-testid="admin-action-menu__toggle-theme"
                endNode={
                  <Inline alignItems="center" gap="xs">
                    <Text colorVariant="inherit" variant="body2" weightVariant="medium">
                      {prefixNS('darkMode')}
                    </Text>
                    <Text colorVariant="inherit" isItalic variant="body4" weightVariant="medium">
                      (Alt + V)
                    </Text>
                  </Inline>
                }
                onChange={() => {
                  setMode(theme.palette.activeMode === 'dark' ? 'light' : 'dark');
                }}
              />
              <ToggleInput
                checked={timeMode}
                data-testid="admin-action-menu__toggle-time"
                endNode={
                  <Inline alignItems="center" gap="xs">
                    <Text colorVariant="inherit" variant="body2" weightVariant="medium">
                      {prefixNS('24HourView')}
                    </Text>
                    <Text colorVariant="inherit" isItalic variant="body4" weightVariant="medium">
                      (Alt + Z)
                    </Text>
                  </Inline>
                }
                onChange={() => {
                  setLocalTimeMode(!timeMode);
                  setTimeMode(!timeMode);
                }}
              />
              <ToggleInput
                checked={!showAdminView}
                data-testid="admin-action-menu__toggle-admin"
                endNode={
                  <Inline alignItems="center" gap="xs">
                    <Text colorVariant="inherit" variant="body2" weightVariant="medium">
                      {prefixNS('customerView')}
                    </Text>
                    <Text colorVariant="inherit" isItalic variant="body4" weightVariant="medium">
                      (Alt + C)
                    </Text>
                  </Inline>
                }
                onChange={() => setView(!showAdminView)}
              />
              <ToggleInput
                checked={showDemoView}
                data-testid="admin-action-menu__toggle-view"
                endNode={
                  <Inline alignItems="center" gap="xs">
                    <Text colorVariant="inherit" variant="body2" weightVariant="medium">
                      {prefixNS('toggleDemoView')}
                    </Text>
                    <Text colorVariant="inherit" isItalic variant="body4" weightVariant="medium">
                      (Alt + X)
                    </Text>
                  </Inline>
                }
                onChange={() => {
                  setDemoView(!showDemoView);
                }}
              />
            </Stack>
            <Hr backgroundColor="common.white" my="s" />
            <Link
              as={RouterLink}
              colorVariant="inherit"
              css={css`
                text-decoration: none;
                :hover {
                  text-decoration: underline;
                }
              `}
              dataTestId="admin-action-menu__sub-view-org"
              to={UrlPaths.ADMIN.ORGANIZATIONS}
              variant="body3"
            >
              <Inline alignItems="center" gap="xs">
                <CaretLeftFilled />
                <div>
                  <Text as="span" colorVariant="inherit" variant="body3" weightVariant="medium">
                    {prefixNS('orgSearch')}
                  </Text>
                  &#32;
                  <Text as="span" colorVariant="inherit" isItalic variant="body4" weightVariant="medium">
                    (Alt + A)
                  </Text>
                </div>
              </Inline>
            </Link>
            <Link
              colorVariant="inherit"
              dataTestId="admin-action-menu__sub-view-cmd"
              onClick={() => {
                setModal(
                  () => (
                    <AdminCmdK
                      is24HrView={timeMode}
                      onTimeToggle={() => {
                        setLocalTimeMode(!timeMode);
                        setTimeMode(!timeMode);
                      }}
                    />
                  ),
                  { dataTestId: 'cmd-k-modal', minHVariant: 's' }
                );
              }}
              variant="body3"
            >
              <Inline alignItems="center" gap="xs">
                <CaretLeftFilled />
                <div>
                  <Text as="span" colorVariant="inherit" variant="body3" weightVariant="medium">
                    {prefixNS('quickSearch')}
                  </Text>
                  &#32;
                  <Text as="span" colorVariant="inherit" isItalic variant="body4" weightVariant="medium">
                    (Alt + K)
                  </Text>
                </div>
              </Inline>
            </Link>
            <Inline alignItems="center">
              <Link
                colorVariant="inherit"
                dataTestId="admin-action-menu__sub-view-flags"
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => togglePopoverView(e, 'flags')}
                variant="body3"
              >
                <Inline alignItems="center" gap="xs">
                  <CaretLeftFilled />
                  {prefixNS('launchDarklyFlags')}
                </Inline>
              </Link>
              <CopyOutlined
                css={css`
                  ${theme.mixins.body3(theme)}
                `}
                data-testid="admin-action-menu__btn--copy-flags"
                onClick={() => {
                  copy(JSON.stringify(ldFlags));
                  trigger({ content: commonT('copied') });
                }}
                title={commonT('copy')}
              />
            </Inline>
            <Inline alignItems="center">
              <Link
                colorVariant="inherit"
                dataTestId="admin-action-menu__sub-view-capabilities"
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => togglePopoverView(e, 'capabilities')}
                variant="body3"
              >
                <Inline alignItems="center" gap="xs">
                  <CaretLeftFilled />
                  {prefixNS('userPermissions')}
                </Inline>
              </Link>
              <CopyOutlined
                css={css`
                  ${theme.mixins.body3(theme)}
                `}
                data-testid="admin-action-menu__btn--copy-capabilities"
                onClick={() => {
                  copy(JSON.stringify(capabilities));
                  trigger({ content: commonT('copied') });
                }}
                title={commonT('copy')}
              />
            </Inline>
            <Inline alignItems="center">
              <Link
                colorVariant="inherit"
                dataTestId="admin-action-menu__sub-view-toggles"
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => togglePopoverView(e, 'toggles')}
                variant="body3"
              >
                <Inline alignItems="center" gap="xs">
                  <CaretLeftFilled />
                  {prefixNS('organizationToggles')}
                </Inline>
              </Link>
              <CopyOutlined
                css={css`
                  ${theme.mixins.body3(theme)}
                `}
                data-testid="admin-action-menu__btn--copy-toggles"
                onClick={() => {
                  copy(JSON.stringify(toggles));
                  trigger({ content: commonT('copied') });
                }}
                title={commonT('copy')}
              />
            </Inline>
            <Draft.Popover
              anchorEl={anchorEls.current ? anchorEls[anchorEls.current] : null}
              isOpen={Boolean(anchorEls.current && anchorEls[anchorEls.current])}
              middleware={[
                autoPlacement(),
                offset({ mainAxis: 10, crossAxis: 0 }),
                shift({
                  altBoundary: true,
                  rootBoundary: 'document',
                }),
              ]}
              onOpenChange={() => setAnchorEls({ ...anchorEls, [anchorEls.current]: null, current: '' })}
              placement="left"
            >
              <Draft.PopoverContent
                css={css`
                  color: ${theme.palette.background.default};
                  z-index: ${theme.zIndex.tooltip + 1};
                  box-sizing: border-box;
                  border: none;
                  background-color: ${theme.palette.background.tooltip};
                  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
                  border-radius: ${theme.shape.borderRadius.s};
                `}
                dataTestId={`admin-action-menu__popover-${anchorEls.current}`}
              >
                <Stack
                  data-testid="filter-menu-body"
                  height="x40"
                  overflowY="auto"
                  spacingMixin="inset"
                  textAlign="center"
                >
                  {match(anchorEls.current)
                    .with('flags', () => (
                      <React.Fragment>
                        <Text
                          colorVariant="inherit"
                          variant="body4"
                          weightVariant="semiBold"
                        >{`${Object.keys(ldFlags).length} ${prefixNS('flag', { count: Object.keys(ldFlags).length })}`}</Text>
                        <Hr />
                        {Object.keys(ldFlags)
                          .sort((a, b) => a.localeCompare(b))
                          .map((flag, idx) => {
                            return (
                              <Inline
                                key={`${flag}-${idx}`}
                                alignItems="center"
                                data-testid={`admin-action-menu__toggle-${flag}`}
                                width="scale"
                              >
                                <Text colorVariant="inherit" variant="body4" weightVariant="medium">
                                  {ldFlags[flag] ? (
                                    <CheckCircleFilled
                                      data-testid={`admin-action-menu__toggle-${flag}--checked`}
                                      style={{ color: theme.palette.positive.main }}
                                    />
                                  ) : (
                                    <CloseCircleOutlined
                                      data-testid={`admin-action-menu__toggle-${flag}--unchecked`}
                                      style={{ color: theme.palette.negative.main }}
                                    />
                                  )}
                                </Text>
                                <Text colorVariant="inherit" variant="body4" weightVariant="medium">
                                  {flag}
                                </Text>
                              </Inline>
                            );
                          })}
                      </React.Fragment>
                    ))
                    .with('capabilities', () => (
                      <React.Fragment>
                        <Text
                          colorVariant="inherit"
                          variant="body4"
                          weightVariant="semiBold"
                        >{`${capabilities.length} ${commonT('permission', { count: capabilities.length })}`}</Text>
                        <Hr />
                        {capabilities
                          .slice()
                          .sort()
                          .map((flag, idx) => {
                            return (
                              <Inline
                                key={`${flag}-${idx}`}
                                alignItems="center"
                                data-testid={`admin-action-menu__toggle-${flag}`}
                                justifyContent="space-between"
                                width="scale"
                              >
                                <Text colorVariant="inherit" variant="body4" weightVariant="medium">
                                  {flag}
                                </Text>
                              </Inline>
                            );
                          })}
                      </React.Fragment>
                    ))
                    .with('toggles', () => (
                      <React.Fragment>
                        <Text
                          colorVariant="inherit"
                          variant="body4"
                          weightVariant="semiBold"
                        >{`${toggles.length} ${prefixNS('toggle', { count: toggles.length })}`}</Text>
                        <Hr />
                        {toggles
                          .slice()
                          .sort()
                          .map((flag, idx) => {
                            return (
                              <Inline
                                key={`${flag}-${idx}`}
                                alignItems="center"
                                data-testid={`admin-action-menu__toggle-${flag}`}
                                justifyContent="space-between"
                                width="scale"
                              >
                                <Text colorVariant="inherit" variant="body4" weightVariant="medium">
                                  {flag}
                                </Text>
                              </Inline>
                            );
                          })}
                      </React.Fragment>
                    ))
                    .otherwise(() => null)}
                </Stack>
              </Draft.PopoverContent>
            </Draft.Popover>
            {showSudo && (
              <React.Fragment>
                <Hr backgroundColor="common.white" my="s" />
                <Stack alignItems="center" gap="xs" justifyContent="center" overflow="hidden" textAlign="center">
                  <Text
                    colorVariant="inherit"
                    css={css`
                      width: 100%;
                    `}
                    dataTestId="admin-action-menu__sudo--customer"
                    title={currentCustomer}
                    truncate
                    variant="body3"
                    weightVariant="semiBold"
                  >
                    {currentCustomer}
                  </Text>
                  <Inline>
                    <Text
                      as="span"
                      colorVariant={customer?.planName ? 'inherit' : 'disabled'}
                      dataTestId="admin-action-menu__icon--plan"
                      variant="body3"
                    >
                      <DeploymentUnitOutlined />
                    </Text>
                    <Text
                      as="span"
                      colorVariant={customer?.webPlanName ? 'inherit' : 'disabled'}
                      dataTestId="admin-action-menu__icon--web-plan"
                      variant="body3"
                    >
                      <Broadcast />
                    </Text>
                    <Text
                      as="span"
                      colorVariant={customer?.vodPlanName ? 'inherit' : 'disabled'}
                      dataTestId="admin-action-menu__icon--vod"
                      variant="body3"
                    >
                      <FolderOutlined />
                    </Text>
                  </Inline>
                </Stack>
                <Draft.Button
                  colorVariant="inherit"
                  dataTestId="admin-action-menu__btn--remove-override"
                  label={prefixNS('removeOverride')}
                  onClick={handleSwitchDefault}
                  sizeVariant="s"
                  variant="outlined"
                  width="scale"
                >
                  {prefixNS('removeOverride')}
                </Draft.Button>
              </React.Fragment>
            )}
          </Stack>
        </Inline>
      </div>,
      document.body
    )
  );
};

AdminActionMenuInternal.displayName = 'AdminActionMenuInternal';

export const AdminActionMenu = React.forwardRef(AdminActionMenuInternal);

// eslint-disable-next-line no-redeclare
export namespace AdminActionMenu {
  export type Props = _Props;
}
