import * as React from 'react';
import { Draft } from '@resi-media/resi-ui';
import { produce } from 'immer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createContainer } from 'react-tracked';
import type { SwitchOrgModalState } from '@studio/components/SwitchOrg';
import { SwitchOrgModal } from '@studio/components/SwitchOrg';
import { localStoragePrefix } from '@studio/constants/env-variables';
import { uniqueBy } from '@studio/helpers/unique-by';
import { useIsAuthorized } from '@studio/hooks';
import { selectUser } from '@studio/store/authentication';
import Permissions from '@studio/store/authentication/permissions';
import { fetch, selectCustomerNameData } from '@studio/store/customer';

const CUSTOMER_CACHE_KEY = `${localStoragePrefix}-recent-customers`;
const CUSTOMER_FAVORITE_KEY = `${localStoragePrefix}-favorite-customers`;

type CustomerData = {
  customerId: string;
  customerName: string;
  masterEcg: string | null;
};

type MetaCustomerData = CustomerData & {
  count: number;
  date: string;
};

type AdminContextState = {
  customers: MetaCustomerData[];
  favoriteCustomers: MetaCustomerData[];
  showAdminView: boolean;
  showDemoView: boolean;
};

const initialAdminState: AdminContextState = {
  showAdminView: false,
  showDemoView: false,
  customers: [],
  favoriteCustomers: [],
};

enum ADMIN_ACTION_TYPES {
  SET_VIEW,
  SET_DEMO_VIEW,
  SET_CUSTOMERS,
  SET_FAVORITE_CUSTOMERS,
}

type AdminActions =
  | {
      payload: boolean;
      type: ADMIN_ACTION_TYPES.SET_DEMO_VIEW | ADMIN_ACTION_TYPES.SET_VIEW;
    }
  | {
      payload: MetaCustomerData[];
      type: ADMIN_ACTION_TYPES.SET_CUSTOMERS;
    }
  | {
      payload: MetaCustomerData[];
      type: ADMIN_ACTION_TYPES.SET_FAVORITE_CUSTOMERS;
    };

const adminReducer = (state: AdminContextState, action: AdminActions) => {
  switch (action.type) {
    case ADMIN_ACTION_TYPES.SET_VIEW:
      state.showAdminView = action.payload;
      break;
    case ADMIN_ACTION_TYPES.SET_DEMO_VIEW:
      state.showDemoView = action.payload;
      break;
    case ADMIN_ACTION_TYPES.SET_CUSTOMERS:
      state.customers = action.payload;
      break;
    case ADMIN_ACTION_TYPES.SET_FAVORITE_CUSTOMERS:
      state.favoriteCustomers = action.payload;
      break;
  }
};

type AdminContextHook = AdminContextState & {
  currentCustomer: string;
  frequentCustomers: MetaCustomerData[];
  handleSwitchDefault: () => void;
  isResiAdmin: boolean;
  setCustomerData: (customerInfo: CustomerData) => void;
  setDemoView: (v: boolean) => void;
  setFavoriteCustomer: (customerInfo: CustomerData) => void;
  setView: (v: boolean) => void;
  showSudo: boolean;
  uniqueCustomers: MetaCustomerData[];
};

const curriedReducerFunction = produce(adminReducer);

const useValues = (): readonly [AdminContextHook, () => void] => {
  const reduxDispatch = useDispatch();
  const [localState, dispatch] = React.useReducer(curriedReducerFunction, {
    ...initialAdminState,
    customers: JSON.parse(localStorage.getItem(CUSTOMER_CACHE_KEY) ?? '[]'),
    favoriteCustomers: JSON.parse(localStorage.getItem(CUSTOMER_FAVORITE_KEY) ?? '[]'),
  });
  const isResiAdmin = useIsAuthorized([Permissions.RESI_ADMIN_ONLY]);
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const customerNames = useSelector(selectCustomerNameData);
  const { isOpen, isOverride, onCloseReset, setModal, setModalState } =
    Draft.ModalContext.useModal<SwitchOrgModalState>();

  React.useEffect(() => {
    dispatch({ type: ADMIN_ACTION_TYPES.SET_VIEW, payload: isResiAdmin });
    dispatch({ type: ADMIN_ACTION_TYPES.SET_DEMO_VIEW, payload: !isResiAdmin });
  }, [isResiAdmin]);

  const setFavoriteCustomer = React.useCallback((customerInfo: CustomerData) => {
    const currentCustomerData = JSON.parse(localStorage.getItem(CUSTOMER_FAVORITE_KEY) ?? '[]');
    const foundIndex = currentCustomerData.findIndex((c: CustomerData) => c.customerId === customerInfo.customerId);

    // If the customer is already in the list, remove it
    if (foundIndex > -1) {
      currentCustomerData.splice(foundIndex, 1);
      dispatch({ type: ADMIN_ACTION_TYPES.SET_FAVORITE_CUSTOMERS, payload: currentCustomerData });
      localStorage.setItem(CUSTOMER_FAVORITE_KEY, JSON.stringify(currentCustomerData));
    } else {
      const customerInfoWithDate = { ...customerInfo, date: new Date().toISOString(), count: 0 };
      dispatch({
        type: ADMIN_ACTION_TYPES.SET_FAVORITE_CUSTOMERS,
        payload: currentCustomerData.length ? [...currentCustomerData, customerInfoWithDate] : [customerInfoWithDate],
      });
      localStorage.setItem(
        CUSTOMER_FAVORITE_KEY,
        currentCustomerData.length
          ? JSON.stringify([...currentCustomerData, customerInfoWithDate])
          : JSON.stringify([customerInfoWithDate])
      );
    }
  }, []);

  const setCustomerData = React.useCallback(
    (customerInfo: CustomerData) => {
      if (customerInfo.customerId === user?.customerId) return;

      const currentCustomerData = JSON.parse(localStorage.getItem(CUSTOMER_CACHE_KEY) ?? '[]');
      const customerInfoWithDate = { ...customerInfo, date: new Date().toISOString(), count: 0 };

      dispatch({
        type: ADMIN_ACTION_TYPES.SET_CUSTOMERS,
        payload: currentCustomerData.length ? [...currentCustomerData, customerInfoWithDate] : [customerInfoWithDate],
      });
      localStorage.setItem(
        CUSTOMER_CACHE_KEY,
        currentCustomerData.length
          ? JSON.stringify([...currentCustomerData, customerInfoWithDate])
          : JSON.stringify([customerInfoWithDate])
      );
    },
    [user]
  );

  const setDemoView = React.useCallback((toggleDemoView: boolean) => {
    dispatch({ type: ADMIN_ACTION_TYPES.SET_DEMO_VIEW, payload: toggleDemoView });
  }, []);

  const setView = React.useCallback((toggleAdminView: boolean) => {
    dispatch({ type: ADMIN_ACTION_TYPES.SET_VIEW, payload: toggleAdminView });
  }, []);

  React.useEffect(() => {
    if (isOpen && customerNames && isOverride) {
      setModalState({
        customerName: customerNames.find((name) => name.uuid === user?.actualCustomer)?.name,
      });
    }
  }, [customerNames, isOpen, isOverride, setModalState, user?.actualCustomer]);

  const handleSwitchDefault = React.useCallback(() => {
    if (!customerNames) {
      reduxDispatch(fetch.customerNames.request());
    }

    const handleBeforeSubmit = () => {
      const redirectPath = location.pathname.replace(
        /([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}).*/,
        ''
      );
      navigate(redirectPath, { replace: true });
    };
    setModalState({
      customerId: user?.actualCustomer,
      username: user?.userName,
      onBeforeSubmit: handleBeforeSubmit,
      onSuccess: onCloseReset,
      isOverride: true,
    });
    setModal(() => <SwitchOrgModal />, { dataTestId: 'switch-org-modal', minHVariant: 's' });
  }, [
    customerNames,
    location,
    navigate,
    onCloseReset,
    reduxDispatch,
    setModal,
    setModalState,
    user?.actualCustomer,
    user?.userName,
  ]);

  const state = React.useMemo(
    () => ({
      currentCustomer: user?.customerName ?? '',
      showAdminView: isResiAdmin && localState.showAdminView,
      isResiAdmin,
      favoriteCustomers: localState.favoriteCustomers,
      handleSwitchDefault,
      setCustomerData,
      setFavoriteCustomer,
      showSudo: Boolean(user?.actualCustomer) && !localState.showDemoView,
      showDemoView: localState.showDemoView,
      setDemoView,
      setView,
      customers: localState.customers,
      uniqueCustomers: uniqueBy('customerId', localState.customers.slice()).sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      ),
      frequentCustomers: localState.customers
        .slice()
        .reverse()
        .reduce<MetaCustomerData[]>((agg, customer) => {
          const foundIndex = agg.findIndex((c) => c.customerId === customer.customerId);

          if (foundIndex > -1) {
            agg[foundIndex].count++;
            return agg;
          }
          return [...agg, { ...customer, count: 1 }];
        }, [])
        .sort((a, b) => b.count - a.count),
    }),
    [
      user?.customerName,
      user?.actualCustomer,
      isResiAdmin,
      localState.showAdminView,
      localState.favoriteCustomers,
      localState.showDemoView,
      localState.customers,
      handleSwitchDefault,
      setCustomerData,
      setFavoriteCustomer,
      setDemoView,
      setView,
    ]
  );

  return [
    state,
    () => {
      throw new Error('use functions in the state');
    },
  ];
};

const {
  Provider: AdminProvider,
  useTrackedState: useAdmin,
  useUpdate: useAdminUpdate,
} = createContainer<AdminContextHook, () => void, { children: React.ReactNode }>(useValues);

export { AdminProvider, useAdmin, useAdminUpdate };
export type { AdminContextHook, MetaCustomerData };
