import type { Immutable } from 'immer';
import type { Get } from './get';

export namespace Derived {
  export type Option = {
    label: string;
    value: string;
  };

  export type Preset = Immutable<Get.Preset>;

  export enum VideoFormat {
    AUTO = 'auto',
    NTSC = 'NTSC',
    PAL = 'PAL',
  }

  export type VideoFormatOption = {
    code: string;
    framerate: string;
    height: string;
    isProgressive: boolean;
    name: string;
    recommendedOutput: string;
  };

  export type VideoPreset = Immutable<Get.VideoPreset>;

  export type Form = {
    audioChannel: number;
    bitDepth: number;
    bitratePerAudio: number;
    bitratePerVideo: number;
    codec: string;
    customProfile: boolean;
    formatStandard: VideoFormat;
    generatedName?: string;
    hardwareAcceleration: boolean;
    inputFormatCode: string | null;
    inputOffset: number | null;
    name: string | null;
    outputFormatCode: string | null;
    shortSegments: boolean;
    uuid: string;
    video: VideoPreset[] | null;
    videoChannel: number;
  };

  export namespace V2 {
    export type Form = {
      audioOptions: string;
      ffmpegOptions: string;
      inputOptions: string;
      mapOptions: string;
      name: string;
      uuid: string;
      videoOptions: string;
    };
  }
}
