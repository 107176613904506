import * as React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Draft, Stack, Inline, Text, Link, useToast, Progress } from '@resi-media/resi-ui';
import type { Immutable } from 'immer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Controller, FormProvider, useWatch, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { LAUNCHDARKLY_TOGGLES } from '@studio/constants/launchdarkly-toggle-constants';
import { DOWNLOAD_TYPES } from '@studio/constants/library';
import { PENDO_EVENTS } from '@studio/constants/pendo';
import { TrackEventName, TrackEventProp } from '@studio/constants/tracking-constants';
import { useCues } from '@studio/contexts';
import {
  convertTimeStringToMs,
  extractAudioTracks,
  extractVideoTracks,
  getSortedCues,
  createCueOptions,
} from '@studio/helpers';
import { useIsOverflow, usePrefix, useResizeObserver } from '@studio/hooks';
import { tracking } from '@studio/store';
import type { ContentDestinations, Shared } from '@studio/types';
import { getDefaultChannel } from '../../helpers';
import type { DownloadMediaForm, ModalState, TrimOption } from '../../types';

const Form = ({
  eventSummary,
  isLoadingDownloadUrl,
  onClickDownloadLink,
}: {
  eventSummary: Immutable<ContentDestinations.Get.WebBroadcastSummary>;
  isLoadingDownloadUrl: boolean;
  onClickDownloadLink: (
    d: DownloadMediaForm & { submit?: boolean }
  ) => Promise<Shared.DownloadRequest.Response | undefined>;
}) => {
  const { end, start } = React.useMemo(() => createCueOptions(), []);
  const { eventType, onCloseReset, title, uuid } = Draft.ModalContext.useModal<ModalState>();
  const [endTrimPositions, setEndTrimPositions] = React.useState<TrimOption[]>([]);
  const { commonT, linkT, prefixNS } = usePrefix('components:', 'downloadMedia');
  const dispatch = useDispatch();
  const { cues, fetchCues, notAvailable } = useCues();
  const { trigger } = useToast();
  const { [LAUNCHDARKLY_TOGGLES.DOWNLOAD_AUDIO]: downloadAudio } = useFlags();

  const videoChannelOptions = React.useMemo(() => {
    return extractVideoTracks(eventSummary.videoAdaptationSets);
  }, [eventSummary]);
  const audioChannelOptions = React.useMemo(() => {
    return extractAudioTracks(eventSummary.audioAdaptationSets);
  }, [eventSummary]);
  const cuePositions = React.useMemo(() => {
    if (cues) {
      const _cuePositions = getSortedCues(cues).map((cue) => {
        return { label: `${cue.position} - ${cue.name}`, value: convertTimeStringToMs(cue.position), uuid: cue.uuid };
      });
      return _cuePositions;
    }
    return [];
  }, [cues]);
  const startTrimPositions = React.useMemo(() => {
    return [start, ...cuePositions];
  }, [cuePositions, start]);
  const downloadTypeOptions = [
    { value: DOWNLOAD_TYPES.VIDEO, label: commonT('video') },
    { value: DOWNLOAD_TYPES.AUDIO, label: commonT('audio') },
  ];

  const initialFormValues: DownloadMediaForm = {
    audioChannel: getDefaultChannel(audioChannelOptions, 1),
    downloadType: downloadTypeOptions[0].value,
    startCue: start,
    stopCue: eventSummary.live ? null : end,
    videoBitrate: getDefaultChannel(videoChannelOptions, 0),
  };

  const methods = useForm<DownloadMediaForm>({
    mode: 'all',
    defaultValues: initialFormValues,
    /* 
      Download creation doesn't require stopCues.
      The url requires it to provide a valid stop position for live videos (incomplete manifests) 
      webapp/src/main/java/io/resi/stream/export/controller/ExportController.java
    */
    ...(eventSummary.live && {
      resolver: yupResolver(
        Yup.object({
          stopCue: Yup.object({}).nullable().required(prefixNS('noEndPosition')),
        })
      ),
    }),
  });
  const { clearErrors, control, formState, getFieldState, getValues, handleSubmit, setValue } = methods;
  const { isSubmitting: isFormSubmitting } = formState;
  const downloadType = useWatch({
    control,
    name: 'downloadType',
  });
  const isDownloadTypeVideo = downloadType === DOWNLOAD_TYPES.VIDEO;

  const updateAvailableEndPositions = React.useCallback(
    (currentStartCue: TrimOption) => {
      const endPositions = cuePositions.filter(
        (option) => currentStartCue.uuid === start.uuid || option.value > currentStartCue.value
      );
      const stopCue = getValues('stopCue');
      const { error: stopCueError } = getFieldState('stopCue');

      /** Determine End Position Options based on live status */
      if (!eventSummary.live) {
        endPositions.push(end);
      }

      setEndTrimPositions(endPositions);

      if (!eventSummary.live) {
        if (endPositions.length === 1) {
          setValue('stopCue', endPositions[0]);
        }

        if (
          currentStartCue.uuid !== start.uuid &&
          stopCue &&
          stopCue.value !== -1 &&
          stopCue.value <= currentStartCue.value
        ) {
          setValue('stopCue', end);
        }
      } else {
        if (endPositions.length === 0) {
          setValue('stopCue', null, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
        }

        if (endPositions.length >= 1) {
          if (!stopCue && stopCueError) {
            setValue('stopCue', null);
            clearErrors('stopCue');
          }

          if (stopCue && stopCue.value <= currentStartCue.value) {
            setValue('stopCue', endPositions[0], { shouldValidate: true, shouldDirty: true, shouldTouch: true });
          }
        }
      }
    },
    [clearErrors, cuePositions, end, eventSummary.live, getFieldState, getValues, setValue, start.uuid]
  );

  React.useEffect(() => {
    updateAvailableEndPositions(getValues('startCue'));
  }, [getValues, updateAvailableEndPositions]);

  const [contentNode, contentObserverEntry] = useResizeObserver();
  const [bodyNode, containerObserverEntry] = useResizeObserver();
  const isOverflow = useIsOverflow(containerObserverEntry, contentObserverEntry);

  const handleFormSubmit = React.useCallback(
    async (currentFormState: DownloadMediaForm) => {
      trigger({
        content: prefixNS('preparingYourDownload'),
        timeout: 1000,
      });
      const link = document.createElement('a');
      try {
        const response = await onClickDownloadLink({ ...currentFormState, submit: true });

        if (response) {
          link.href = response.downloadUrl;
          document.body.appendChild(link);
          link.setAttribute('target', '_blank');
          link.setAttribute('download', 'webevent.mp4');
          link.click();

          onCloseReset();

          dispatch({
            type: tracking.ActionTypes.TRACK_SEGMENT_REQUEST,
            payload: {
              eventName: TrackEventName.EVENT_DOWNLOAD,
              eventProps: {
                [TrackEventProp.TRANSCODED_EVENT_UUID]: uuid,
              },
            },
          });
          pendo.track(
            currentFormState.downloadType === DOWNLOAD_TYPES.VIDEO
              ? PENDO_EVENTS.MEDIA.DOWNLOAD.VIDEO.FILE
              : PENDO_EVENTS.MEDIA.DOWNLOAD.AUDIO.FILE,
            { type: eventType }
          );
        }
      } catch (error) {
        console.error(error);
        trigger({
          content: prefixNS('downloadFailure', {
            mediaTitle: title,
          }),
        });
      } finally {
        link.remove();
      }
    },
    [dispatch, eventType, onClickDownloadLink, onCloseReset, prefixNS, title, trigger, uuid]
  );

  return (
    <FormProvider {...methods}>
      <Draft.ModalBody ref={bodyNode}>
        <form data-testid="download-media__form" id="download-form" onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack ref={contentNode} gap="l">
            {eventSummary.hevc && (
              <Draft.AlertBanner dataTestId="download-media__form--warning--download-allowed" variant="warning">
                {prefixNS('downloadWithCaution')}
              </Draft.AlertBanner>
            )}
            {/* TODO: Remove for now as to match control parity
                {/* <Draft.FormField fieldLabel={commonT('trimVideo')} htmlFor="trim-slider">
                    <Draft.Slider
                      ariaLabel={['Lower thumb', 'Upper thumb']}
                      ariaLabelledby={['']}
                      defaultValue={[0, 100]}
                      max={100}
                      maxTextNode={prefixNS('endOfVideo')}
                      min={0}
                      minTextNode={prefixNS('startOfVideo')}
                    />
                  </Draft.FormField> */}
            <Text as="div">
              <Stack gap="xs">
                {prefixNS('compatibilityMsg')}
                <Link href={linkT('downloadEventVideo')} rel="noopener noreferrer" target="_blank" variant="body3">
                  {commonT('sentenceCase', { value: commonT('learnMore') })}
                </Link>
              </Stack>
            </Text>
            <Inline data-testid="download-media__cues">
              <Controller
                control={control}
                name="startCue"
                render={({ field: { name, onBlur, onChange, ref, value }, fieldState: { isTouched } }) => {
                  return (
                    <Draft.FormField
                      fieldLabel={prefixNS('startPosition')}
                      helpText={value.value !== start.value && prefixNS('cueStartHelpText')}
                      htmlFor={name}
                      touched={isTouched}
                    >
                      <Draft.Select<TrimOption>
                        ref={ref}
                        appendToBody
                        dataTestId={name}
                        inputId={name}
                        onBlur={onBlur}
                        onChange={(option) => {
                          if (option) {
                            onChange(option);
                            updateAvailableEndPositions(option);
                          }
                        }}
                        options={startTrimPositions}
                        value={startTrimPositions.find((v) => v.uuid === value.uuid)}
                      />
                    </Draft.FormField>
                  );
                }}
              />
              <Controller
                control={control}
                name="stopCue"
                render={({
                  field: { name, onBlur, onChange, ref, value },
                  fieldState: { error, isDirty, isTouched },
                  formState: { isSubmitted },
                }) => {
                  return (
                    <Draft.FormField
                      error={error?.message}
                      fieldLabel={prefixNS('stopPosition')}
                      helpText={value && value.value !== end.value && prefixNS('cueStopHelpText')}
                      htmlFor={name}
                      touched={isDirty || isTouched || isSubmitted}
                    >
                      <Draft.Select<TrimOption>
                        ref={ref}
                        appendToBody
                        dataTestId={name}
                        disabled={eventSummary.live && endTrimPositions.length === 0}
                        hasError={Boolean(error)}
                        inputId={name}
                        onBlur={onBlur}
                        onChange={(option) => option && onChange(option)}
                        options={endTrimPositions}
                        value={endTrimPositions.find((v) => v.uuid === value?.uuid)}
                      />
                    </Draft.FormField>
                  );
                }}
              />
            </Inline>
            {fetchCues.error && !notAvailable && (
              <Draft.AlertBanner dataTestId="error-loading-cues" variant="warning">
                {prefixNS('errorLoadingCues')}
              </Draft.AlertBanner>
            )}
            {notAvailable && (
              <Draft.AlertBanner dataTestId="error-cues-not-available" variant="warning">
                {prefixNS('cuesNotAvailableWarningMsg')}
              </Draft.AlertBanner>
            )}
            {downloadAudio && (
              <Controller
                control={control}
                name="downloadType"
                render={({ field: { name, onBlur, onChange, ref, value }, fieldState: { isTouched } }) => {
                  return (
                    <Draft.FormField fieldLabel={prefixNS('downloadType')} htmlFor={name} touched={isTouched}>
                      <Draft.Select
                        ref={ref}
                        appendToBody
                        dataTestId={name}
                        inputId={name}
                        onBlur={onBlur}
                        onChange={(option) => option && onChange(option.value)}
                        options={downloadTypeOptions}
                        value={downloadTypeOptions.find((v) => v.value === value)}
                      />
                    </Draft.FormField>
                  );
                }}
              />
            )}
            <Inline {...(!isDownloadTypeVideo && { pr: 'xs', width: '1/2' })}>
              {isDownloadTypeVideo && (
                <Controller
                  control={control}
                  name="videoBitrate"
                  render={({ field: { name, onBlur, onChange, ref, value }, fieldState: { isTouched } }) => {
                    return (
                      <Draft.FormField fieldLabel={prefixNS('videoBitrate')} htmlFor={name} touched={isTouched}>
                        <Draft.Select
                          ref={ref}
                          appendToBody
                          dataTestId={name}
                          inputId={name}
                          onBlur={onBlur}
                          onChange={(option) => option && onChange(option.value)}
                          options={videoChannelOptions}
                          value={videoChannelOptions.find((v) => v.value === value)}
                        />
                      </Draft.FormField>
                    );
                  }}
                />
              )}
              <Controller
                control={control}
                name="audioChannel"
                render={({ field: { name, onBlur, onChange, ref, value }, fieldState: { isTouched } }) => {
                  const isOnlyOneOption = audioChannelOptions.length === 1;
                  return (
                    <Draft.FormField fieldLabel={prefixNS('audioChannels')} htmlFor={name} touched={isTouched}>
                      <Draft.Select
                        ref={ref}
                        appendToBody
                        dataTestId={name}
                        disabled={isOnlyOneOption}
                        inputId={name}
                        onBlur={onBlur}
                        onChange={(option) => option && onChange(option.value)}
                        options={audioChannelOptions}
                        readOnly={isOnlyOneOption}
                        value={audioChannelOptions.find((v) => v.value === value)}
                      />
                    </Draft.FormField>
                  );
                }}
              />
            </Inline>
          </Stack>
        </form>
      </Draft.ModalBody>
      <Draft.ModalFooter showBorder={isOverflow}>
        <Inline alignItems="center" gap="l" justifyContent="flex-end">
          <Draft.Button
            data-testid="download-media__btn--link"
            disabled={isFormSubmitting || isLoadingDownloadUrl}
            label={prefixNS('createDownloadLink')}
            onClick={handleSubmit(onClickDownloadLink)}
            {...(isLoadingDownloadUrl && {
              startNode: (
                <Progress colorVariant="inherit" dataTestId="download-media__progress" sizeVariant="inherit" />
              ),
            })}
            variant="text"
          />
          <Draft.Button
            data-testid="download-media__btn--submit"
            data-trackid="download-web-video-submit"
            disabled={isFormSubmitting || isLoadingDownloadUrl}
            form="download-form"
            label={commonT('download')}
            type="submit"
            {...(isFormSubmitting && {
              startNode: <Progress colorVariant="inherit" sizeVariant="inherit" />,
            })}
          />
        </Inline>
      </Draft.ModalFooter>
    </FormProvider>
  );
};

Form.displayName = 'Form';

export default Form;
