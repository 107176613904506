import React from 'react';
import { Draft, Inline, Stack, Text } from '@resi-media/resi-ui';
import { CopyToClipboard } from '@studio/components/CopyToClipboard';
import { PENDO_EVENTS } from '@studio/constants/pendo';
import { usePrefix } from '@studio/hooks';

type _Props = {
  audioDownloadUrl?: string;
  videoDownloadUrl?: string;
};

const DownloadLinks = ({ audioDownloadUrl, videoDownloadUrl }: _Props) => {
  const { eventType, onCloseReset } = Draft.ModalContext.useModal();
  const { commonT, prefixNS } = usePrefix('components:', 'downloadMedia');

  return (
    <React.Fragment>
      <Draft.ModalBody>
        <Stack gap="l">
          <Text>{prefixNS('downloadLinkCreated')}</Text>
          {videoDownloadUrl && (
            <Draft.FormField fieldLabel={prefixNS('videoDownloadLink')}>
              <Inline alignItems="center">
                <Draft.TextInput dataTestId="video-download-link" defaultValue={videoDownloadUrl} readOnly />
                <CopyToClipboard
                  name={prefixNS('downloadLink')}
                  onClick={() => pendo.track(PENDO_EVENTS.MEDIA.DOWNLOAD.VIDEO.LINK, { type: eventType })}
                  text={videoDownloadUrl}
                />
              </Inline>
            </Draft.FormField>
          )}
          {audioDownloadUrl && (
            <Draft.FormField fieldLabel={prefixNS('audioDownloadLink')}>
              <Inline alignItems="center">
                <Draft.TextInput dataTestId="audio-download-link" defaultValue={audioDownloadUrl} readOnly />
                <CopyToClipboard
                  name={prefixNS('downloadLink')}
                  onClick={() => pendo.track(PENDO_EVENTS.MEDIA.DOWNLOAD.AUDIO.LINK, { type: eventType })}
                  text={audioDownloadUrl}
                />
              </Inline>
            </Draft.FormField>
          )}
        </Stack>
      </Draft.ModalBody>
      <Draft.ModalFooter>
        <Draft.Button data-testid="submit-cancel" label={commonT('close')} onClick={onCloseReset} />
      </Draft.ModalFooter>
    </React.Fragment>
  );
};

DownloadLinks.displayName = 'DownloadLinks';

export default DownloadLinks;
