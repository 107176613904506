export const PENDO_EVENTS = {
  MEDIA: {
    DOWNLOAD: {
      AUDIO: {
        LINK: 'media_download_audio_link',
        FILE: 'media_download_audio_file',
      },
      VIDEO: {
        LINK: 'media_download_video_link',
        FILE: 'media_download_video_file',
      },
    },
    LIBRARY: {
      SEARCH: 'media_library_search',
      TRIM: 'media_library_trim',
    },
    PLAYLISTS: {
      CREATE_SEARCH: 'media_playlist_create_search',
      EDIT_SEARCH: 'media_playlist_edit_search',
    },
  },
  ANALYTICS: {
    LIBRARY: {
      LIST: {
        SEARCH: 'analytics_library_list_search',
      },
    },
  },
  PLAYER_PROFILES: {
    CREATE: 'player_profiles_create',
    EDIT: 'player_profiles_edit',
    DELETE: 'player_profiles_delete',
  },
};
