import type { Theme, SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

export const S = {
  profileButton: (_theme: Theme): SerializedStyles => css`
    max-width: 190px;
  `,
  profileAvatar: (theme: Theme): SerializedStyles => css`
    flex: 0;
    border-radius: ${theme.shape.borderRadius.circle};
    width: 26px;
    border: 1px solid ${theme.palette.border};
  `,
  accountPopUpOptionWrap: (theme: Theme): SerializedStyles => css`
    background-color: ${theme.palette.background.paper};
    box-shadow: ${theme.shadows[1]};
    box-sizing: border-box;
    color: ${theme.palette.text.title};
    width: 100%;

    ${theme.mq.md} {
      min-width: 320px;
      width: auto;
    }
  `,
  profilePhoto: (theme: Theme): SerializedStyles => css`
    img {
      border-radius: ${theme.shape.borderRadius.circle};
      height: 40px;
      width: 40px;
      border: 2px solid ${theme.palette.border};
    }
  `,
  detailsWrap: (theme: Theme): SerializedStyles => css`
    border-top: 1px solid ${theme.palette.divider};
  `,
  signOutWrap: (theme: Theme): SerializedStyles => css`
    border-top: 1px solid ${theme.palette.divider};
  `,
};
