import * as React from 'react';
import { Draft, Inline, Progress, Stack, Text } from '@resi-media/resi-ui';
import { useDispatch } from 'react-redux';
import { usePrefix } from '@studio/hooks';
import { useTypedSelector } from '@studio/store';
import type { Credentials } from '@studio/store/authentication';
import { AuthenticationActionTypes } from '@studio/store/authentication';
import { SwitchOrgModalForm } from '../components/SwitchOrgModalForm';
import type { SwitchOrgModalState } from '../types';

const SwitchOrgModal = () => {
  const dispatch = useDispatch();
  const { commonT, prefixNS } = usePrefix('pages:', 'adminSearch');
  const { customerId, customerName, onBeforeSubmit, onCloseReset, onSuccess, username } =
    Draft.ModalContext.useModal<SwitchOrgModalState>();
  const { error, isLoggingIn } = useTypedSelector((state) => state.authentication);
  const loginAttemptedRef = React.useRef(false);

  const handleSubmit = (password: string) => {
    onBeforeSubmit?.();
    const credentials: Credentials = {
      username,
      password,
      customerId,
    };
    dispatch({ type: AuthenticationActionTypes.IS_LOGGING_IN });
    dispatch({ type: AuthenticationActionTypes.AUTH_REQUEST, payload: credentials });
    loginAttemptedRef.current = true;
  };

  React.useEffect(() => {
    if (loginAttemptedRef.current && !isLoggingIn && !error) {
      onSuccess({ customerId, customerName });
    }
  }, [customerId, customerName, error, isLoggingIn, onCloseReset, onSuccess]);

  React.useEffect(() => {
    dispatch({ type: AuthenticationActionTypes.AUTH_REQUEST_CANCEL });
  }, [dispatch]);

  return (
    <>
      <Draft.ModalHeader
        dataTestId="switch-org-modal-header"
        header={prefixNS('switchToOrg')}
        onClose={onCloseReset}
        showBorder
      />
      <Draft.ModalBody dataTestId="switch-org-modal-body">
        <Stack gap="m">
          <Text>{prefixNS('retypePassword')}</Text>
          <Inline gap="m">
            <Text weightVariant="bold">{prefixNS('switchingTo')}</Text>
            <Text>{customerName}</Text>
          </Inline>
          {error && <Draft.AlertBanner dataTestId="error-alert">{prefixNS('incorrectPassword')}</Draft.AlertBanner>}
          <SwitchOrgModalForm onSubmit={handleSubmit} username={username} />
        </Stack>
      </Draft.ModalBody>
      <Draft.ModalFooter showBorder={false}>
        <Inline gap="l">
          <Draft.Button
            dataTestId="switch-org-modal-cancel-button"
            id="switch-org-modal-cancel-button"
            label={commonT('cancel')}
            onClick={onCloseReset}
            variant="text"
          />
          <Draft.Button
            {...(isLoggingIn && {
              startNode: (
                <Progress colorVariant="inherit" dataTestId="switch-org-modal__progress" sizeVariant="inherit" />
              ),
            })}
            dataTestId="switch-org-modal-submit-button"
            form="switch-org-modal-form"
            id="switch-org-modal-submit-button"
            label={commonT('login')}
            type="submit"
          />
        </Inline>
      </Draft.ModalFooter>
    </>
  );
};

SwitchOrgModal.displayName = 'SwitchOrgModal';

export default SwitchOrgModal;
