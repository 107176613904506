import * as React from 'react';
import { css, Global } from '@emotion/react';
import {
  theme as resiTheme,
  BreakpointProvider,
  Draft,
  ThemeProvider,
  ToastProvider,
  BreakpointConsumer,
} from '@resi-media/resi-ui';
import { createStore, Provider as JotaiProvider } from 'jotai';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary, ErrorBoundaryFallback, GlobalListeners, HttpsRedirect } from '@studio/components';
import Loading from '@studio/pages/Loading';
import { useTypedSelector } from '@studio/store';
import { PageMetaProvider } from './contexts';
import { AdminProvider } from './contexts/admin';
import type { GlobalAction, GlobalContextState } from './contexts/global';
import GlobalContext, { globalReducer, initialGlobalState } from './contexts/global';
import { reset } from './styles/reset-css';

const AuthenticatedApp = React.lazy(
  () => import(/* webpackChunkName: "authenticatedApp" */ /* webpackPrefetch: true */ './authenticated-app')
);
const UnauthenticatedApp = React.lazy(
  () => import(/* webpackChunkName: "unauthenticatedApp" */ './unauthenticated-app')
);

const App = () => {
  const { user } = useTypedSelector((storeState) => storeState.authentication);
  const [state, dispatch] = React.useReducer<React.Reducer<GlobalContextState, GlobalAction>>(
    globalReducer,
    initialGlobalState
  );

  // NOTE: defining the a default store and providing it to the JotaiProvider clears the store on logout because this component will unmount
  const defaultStore = createStore();

  return (
    <JotaiProvider store={defaultStore}>
      <PageMetaProvider>
        <BreakpointProvider queries={resiTheme.mq}>
          <BreakpointConsumer>
            {() => {
              const isDesktopPage = !document.querySelector("meta[content='width=device-width, initial-scale=1.0']");
              return (
                <ThemeProvider
                  theme={{
                    ...resiTheme,
                    mq: {
                      xs: isDesktopPage ? '@media all' : resiTheme.mq.xs,
                      sm: isDesktopPage ? '@media all' : resiTheme.mq.sm,
                      md: isDesktopPage ? '@media all' : resiTheme.mq.md,
                      lg: isDesktopPage ? '@media all' : resiTheme.mq.lg,
                      xl: isDesktopPage ? '@media all' : resiTheme.mq.xl,
                      xxl: isDesktopPage ? '@media all' : resiTheme.mq.xxl,
                    },
                  }}
                >
                  <HttpsRedirect>
                    <Global
                      styles={(theme) => css`
                        ${reset}
                        body {
                          text-rendering: optimizeLegibility;
                          background: ${theme.palette.background.default};
                          font-family: ${theme.typography.fontFamilyBody};
                          margin: 0;
                          overflow: auto;
                          height: 100%;
                        }
                      `}
                    />
                    <React.Suspense fallback={<Loading />}>
                      <GlobalContext.Provider value={{ state, dispatch }}>
                        <ToastProvider>
                          <Draft.LocalizationProvider>
                            <ErrorBoundary fallback={(props) => <ErrorBoundaryFallback {...props} />}>
                              <Router>
                                <Draft.ModalProvider>
                                  <AdminProvider>
                                    <GlobalListeners>
                                      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
                                    </GlobalListeners>
                                  </AdminProvider>
                                </Draft.ModalProvider>
                              </Router>
                            </ErrorBoundary>
                          </Draft.LocalizationProvider>
                        </ToastProvider>
                      </GlobalContext.Provider>
                    </React.Suspense>
                  </HttpsRedirect>
                </ThemeProvider>
              );
            }}
          </BreakpointConsumer>
        </BreakpointProvider>
      </PageMetaProvider>
    </JotaiProvider>
  );
};

export default App;
